import { Swiper, SwiperSlide } from 'swiper/react'
import { Lazy, Navigation } from 'swiper'
import Section from 'src/components/sections/Section'
import Link from 'src/components/ui/Link'
import GridSkeleton from 'src/components/sports/utils/skeleton/banners/banners'
import { Suspense } from 'react'

import './bannersSlider.scss'

const GalleryGridSkeleton = <GridSkeleton loading />

// eslint-disable-next-line
const BannersSlider = (dataCMS: any) => {
  return (
    <>
      {dataCMS && (
        <Suspense fallback={GalleryGridSkeleton}>
          <div className="content-slider mt-12 md:mt-14 layout__content">
            {dataCMS.data.titleCarroussel && (
              <h2 className="text-neutral10 text-2xl uppercase italic">
                {dataCMS.data.titleCarroussel}
              </h2>
            )}
            {dataCMS.data.cardsCarroussel && (
              <div>
                <Section className="banners-section layout__content">
                  <ul className="pb-11 md:pb-14">
                    <div className="swiper-arrows swiper-button-prev swiper-button-banners-prev !flex" />
                    <div className="swiper-arrows swiper-button-next swiper-button-banners-next !flex" />
                    <Swiper
                      className="banner-categories-swiper"
                      // loop
                      navigation={{
                        prevEl: '.swiper-button-banners-prev',
                        nextEl: '.swiper-button-banners-next',
                      }}
                      lazy={{
                        loadPrevNext: true,
                        loadPrevNextAmount: 1,
                      }}
                      spaceBetween={13}
                      slidesPerView={1.5}
                      modules={[Navigation, Lazy]}
                      breakpoints={{
                        767: {
                          slidesPerView: 3.5,
                          spaceBetween: 32,
                        },
                      }}
                    >
                      {dataCMS.data.cardsCarroussel.map(
                        // eslint-disable-next-line
                        (item: any, index: number) => {
                          return (
                            <SwiperSlide key={index}>
                              <li className="flex flex-col">
                                <Link className="block" href={item.URLWeb}>
                                  <img
                                    src={item.imageSport}
                                    alt={item.sportName}
                                    title={item.sportName}
                                  />
                                  {item.sportName !== '' && (
                                    <h2 className="text-neutral10 font-bold mt-3">
                                      {item.sportName}
                                    </h2>
                                  )}
                                </Link>
                              </li>
                            </SwiperSlide>
                          )
                        }
                      )}
                    </Swiper>
                  </ul>
                  <div className="border-line layout__content" />
                </Section>
              </div>
            )}
          </div>
        </Suspense>
      )}
    </>
  )
}

export default BannersSlider
