import { Swiper, SwiperSlide } from 'swiper/react'
import { Lazy, Navigation } from 'swiper'
import Section from 'src/components/sections/Section'
import Link from 'src/components/ui/Link'

import './categoriesSlider.scss'

// eslint-disable-next-line
const CategoriesSlider = (dataCMS: any) => {
  return (
    <>
      {dataCMS && (
        <div className="content-slider mt-12 md:mt-14 layout__content">
          {dataCMS.data.titleCarroussel && (
            <h2 className="text-neutral10 text-2xl uppercase italic">
              {dataCMS.data.titleCarroussel}
            </h2>
          )}
          {dataCMS.data.cardsCarroussel && (
            <div>
              <Section className="banner-section-categories layout__content">
                <ul>
                  <div className="swiper-arrows swiper-button-prev swiper-button-categories-prev !flex" />
                  <div className="swiper-arrows swiper-button-next swiper-button-categories-next !flex" />
                  <Swiper
                    className="banner-categories-swiper"
                    // loop
                    navigation={{
                      prevEl: '.swiper-button-categories-prev',
                      nextEl: '.swiper-button-categories-next',
                    }}
                    lazy={{
                      loadPrevNext: true,
                      loadPrevNextAmount: 1,
                    }}
                    spaceBetween={16}
                    slidesPerView={2.5}
                    modules={[Navigation, Lazy]}
                    breakpoints={{
                      767: {
                        slidesPerView: 4.5,
                      },
                      420: {
                        slidesPerView: 3.5,
                      },
                    }}
                  >
                    {dataCMS.data.cardsCarroussel.map(
                      // eslint-disable-next-line
                      (item: any, index: number) => {
                        return (
                          <SwiperSlide key={index}>
                            <li className="flex items-center flex-col md:flex-row">
                              <Link className="flex" href={item.URLWeb}>
                                <img
                                  src={item.imageCategory}
                                  alt={item.categoryName}
                                  title={item.categoryName}
                                />
                                {item.categoryName !== '' && (
                                  <p>
                                    <span className="text-neutral10 text-base">
                                      {item.categoryName}
                                    </span>
                                  </p>
                                )}
                              </Link>
                            </li>
                          </SwiperSlide>
                        )
                      }
                    )}
                  </Swiper>
                </ul>
                <div className="border-line layout__content" />
              </Section>
            </div>
          )}
        </div>
      )}
    </>
  )
}

export default CategoriesSlider
